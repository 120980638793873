.default {
  &.home {
    .inspiration {
      padding: 5px 0;

      .inspiration {
        @include make-row();

        margin: -2.5px !important;
        @extend .p-0;

        .item {
          @include make-col-ready();
          @include make-col(12);
    
          @include media-breakpoint-up(xl) {
            @include make-col(4);
          }

          padding: 2.5px;
        }
      }
    }

    .photoalbum {
      .container-fluid {
        padding: 0 0.25rem;
      }
    }
  }
}