.default {
  &.service_overview {
    .service_overview {
      @include make-row();

      .item {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(3);
        }

        @extend .mb-3;
        @extend .mb-sm-4;
      }
    }
  }

  &.service_category {
    .service_category_detail {
			@include make-row();
			@extend .pb-5;

			.info {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pr-lg-5;
			}

			.content {
				@include make-col-ready();
				@include make-col(12);
				@extend .d-flex;
        @extend .flex-column;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pl-lg-5;
			}
			.btn-back {
				@extend .btn;
				@extend .btn-pink;
				@extend .align-self-end;
				@extend .mb-3;
			}
    }
    
    .service_category_overview {
      @include make-row();

			.item {
				@include make-col-ready();
				@include make-col(12);
	
				@include media-breakpoint-up(md) {
					@include make-col(6);
				}
				@include media-breakpoint-up(lg) {
					@include make-col(4);
				}
				@include media-breakpoint-up(xl) {
					@include make-col(3);
				}
	
				@extend .mb-3;
				@extend .mb-sm-4;
			}
		}
  }

  &.service_detail {
		.service_detail {
			@include make-row();
			@extend .pb-5;

			.info {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pr-lg-5;
			}

			.content {
				@include make-col-ready();
				@include make-col(12);
				@extend .d-flex;
        @extend .flex-column;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pl-lg-5;

				.btn-back {
					@extend .btn;
					@extend .btn-pink;
					@extend .align-self-end;
					@extend .mb-3;
				}
			}
		}
	}
}