.gallery {
  .gallery-holder {
    @include make-row();

    .highlight {
      @include make-col-ready();
      @include make-col(12);

      a {
        @extend .d-block;
      }

      .img-fluid {
        @extend .w-100;
      }
    }

    .item {
      @include make-col-ready();
      @include make-col(6);

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      @extend .mt-4;
      
      a {
        @extend .d-block;
      }

      .img-fluid {
        @extend .w-100;
      }
    }
  }
}