/*  ==========================================================================
    base
    ========================================================================== */
/*  .bg-carousel
    ========================================================================== */
    .bg-carousel {
      position: relative;
      min-height: 35vh;

      background-color: #DDDDDD;

      .carousel {
        .carousel-inner {
          .carousel-item {
            min-height: 35vh;

            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            .carousel-holder {
              display: flex;
              align-items: center;
              min-height: 35vh;

              .carousel-caption {
                position: relative;
                right: unset;
                bottom: unset;
                left: unset;

                display: none;
                text-align: left;
              }
            }
          }
        }
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        margin-top: -25px;
        margin-left: -25px;

        font-family: "Font Awesome 5 Pro";
        font-size: 50px;
        line-height: 50px;
        color: #BBBBBB;

        content: "\f1ce";

        animation: fa-spin 2s infinite linear;
      }

      &.large {
        min-height: 70vh;

        .carousel {
          .carousel-inner {
            .carousel-item {
              min-height: 70vh;

              .carousel-holder {
                min-height: 70vh;

                .carousel-caption {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    @keyframes fa-spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

/*  ==========================================================================
    eyecatcher
    ========================================================================== */
    .eyecatcher {
      &.bg-carousel {
        .carousel {
          .carousel-inner {
            .carousel-item {
              .carousel-holder {
                .container { max-width: 1400px; }

                .carousel-caption {
                  padding-top: 150px;
                  padding-bottom: 140px;
                  text-align: center;

                  .carousel-title {
                    font-family: $font-family-secondary;
                    font-size: 2.5rem; /* 40px; */
                    font-weight: bold;
                  }
                }
              }
            }
          }

          .carousel-control-prev,
          .carousel-control-next,
          .carousel-indicators {
            display: none;
          }
        }

        &.large {
          min-height: calc(100vh - 80px);

          .carousel {
            .carousel-inner {
              .carousel-item {
                min-height: calc(100vh - 80px);

                .carousel-holder {
                  min-height: calc(100vh - 80px);
                }
              }
            }
          }
        }
      }
    }
