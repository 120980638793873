    // TOMMY
    $color-lightblue: #33a4b7;
    $color-darkblue: #0476A6;
    $color-pink: #8c495d;
    $color-dark: #3A434B;


    $zeb-color-primary: $color-lightblue;
    $zeb-color-secondary: $color-pink;
    $zeb-color-tertiary: $color-darkblue;

/*  ==========================================================================
    ALL WIDGETS
    ========================================================================== */

    body wm-mini-search-book-widget .wm-mini-search-book {
        place-content: start!important;
    }
    body.home body wm-mini-search-book-widget .wm-mini-search-book {
        margin-top: 40px;
    }

    body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-country-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-filters,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-park-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-period-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection {
        background: none;
    }

    body wm-mini-search-book-widget .wm-container .wm-title,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button.changed,
    body wm-mini-search-book-widget .wm-active-travel-group p,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button.changed .wm-active-travel-group p,
    body wm-mini-search-book-widget .wm-container wm-date-selection .wm-date-picker-button.changed .wm-selected-date p,
    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .btn-link:hover,

    body wm-search-book-widget .wm-container .wm-title,
    body wm-search-book-widget .wm-container .wm-travel-group-button.changed,
    body wm-search-book-widget .wm-active-travel-group p,
    body wm-search-book-widget .wm-container .wm-travel-group-button.changed .wm-active-travel-group p,
    body wm-search-book-widget .wm-container .wm-date-picker-button.changed .wm-selected-date p,
    body wm-search-book-widget .wm-container .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value,
    body wm-search-book-widget .wm-container .wm-type-selection-button.changed .wm-selected-type,
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn.more-info,

    body wm-matrix-widget .wm-selection-row .travel-group-selection .wm-title,
    body wm-matrix-widget .wm-selection-row .wm-date-selection .wm-title,
    body wm-matrix-widget .wm-container wm-travel-group-selection .wm-travel-group-button.changed .wm-active-travel-group p,
    body wm-matrix .wm-selection-row .travel-group-selection .wm-title,
    body wm-matrix .wm-selection-row .wm-date-selection .wm-title,
    body wm-matrix .wm-selection-row .wm-date-selection .wm-date-picker-button.changed .wm-selected-month,
    body wm-matrix .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value
    {
        color: $zeb-color-secondary;
    }

    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value,
    wm-no-items-available h2 {
        color: $zeb-color-primary;
    }

    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus i,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus svg,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus i,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus svg,
    body wm-search-book-widget .wm-results-list .fa-exclamation-triangle,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus i,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus svg,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus i,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus svg,
    wm-no-items-available .fa-exclamation-triangle
    {
        color: $zeb-color-tertiary!important;
    }

    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.range:not(.faded),
    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:hover:not(.disabled),
    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:not(.disabled).focused,
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.range:not(.faded),
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:hover:not(.disabled),
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:not(.disabled).focused
    {
        background-color: $zeb-color-tertiary!important;
    }

    body wm-search-book-widget .wm-container .wm-btn.wm-btn-primary,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search
    {
        background-color: $zeb-color-tertiary!important;
    }

    body wm-mini-search-book-widget .wm-container .wm-btn.wm-btn-search:hover,
    body wm-search-book-widget .wm-container .wm-btn.wm-btn-search:hover,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search:hover,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search:focus
    {
        background-color: $zeb-color-secondary!important;
    }
    body wm-mini-search-book-widget .wm-container .btn.wm-btn,
    body wm-search-book-widget .wm-container .btn.wm-btn {
        border: none!important;
    }

    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.faded,
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.faded,
    body wm-matrix .wm-selection-row .wm-date-selection-item.selected,
    body wm-matrix .wm-selection-row .wm-date-selection-item:hover
    {
        background-color: rgba($zeb-color-tertiary, 0.25)!important;
    }

    body wm-search-book-widget .wm-container .btn-group-toggle .btn
    {
        background-color: rgba($zeb-color-primary, 0.5)!important;
        padding: 5px 15px;
        border: none!important;
    }
    body wm-search-book-widget .wm-container .btn-group-toggle .btn.active,
    body wm-search-book-widget .wm-container .btn-group-toggle .btn:hover
    {
        background-color: rgba($zeb-color-primary, 1)!important;
    }
    body wm-matrix .wm-container .wm-btn.wm-btn-search {
        background: $zeb-color-secondary;
        border: none!important;
        box-shadow: none;
        height: inherit;
        border-radius: 3rem;
        padding: 8px 20px;
    }
    body wm-matrix .wm-container .wm-btn.wm-btn-search:hover {
        background: $zeb-color-primary;
    }
    .wm-container .wm-btn.focus {
        box-shadow: none;
    }

    // OTHER SETTINGS THAN COLORS
    body wm-search-book-widget .wm-search-book .checkbox-list ul li
    {
        width: auto!important;
        margin-right: 60px;
    }
    body wm-search-book-widget .xwm-type-selection-button p.wm-title {
        display: none;
    }

    body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-country-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-filters,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-park-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-period-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection {
        padding: 5px;
        margin-bottom: 15px;
    }

    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button,
    body wm-search-book-widget .wm-container wm-date-selection .wm-date-picker-button,
    body wm-matrix .wm-container wm-travel-group-selection .wm-travel-group-button,
    body wm-matrix .wm-container wm-date-selection .wm-date-picker-button,
    body wm-matrix .wm-container wm-matrix-filter .wm-date-selection .wm-date-picker-button {
        position: relative;
    }
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn.more-info {
        text-transform: lowercase;
    }


    // flex positions
    body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        flex: 1 1 100%!important;
        max-width: 100%!important;
    }

    @media only screen and (max-width: 639px) {
        body wm-search-book-widget .wm-search-book .checkbox-list ul li
        {
            width: 90px!important;
            margin-right: 0;
            margin-bottom: 30px;
            text-align: center;
        }
    }




/*  ==========================================================================
    RESULTS
    ========================================================================== */

    body wm-search-book-widget .wm-container p
    {
        color: $zeb-color-primary;
    }
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-result-item-content .wm-result-item-title,
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-full-price p:hover
    {
        color: $zeb-color-tertiary;
    }
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-result-item-content .wm-result-item-subtitle
    {
        color: $zeb-color-primary;
    }

    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .showmatrix:hover,
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn-book:hover,
    .wm-container .wm-btn.wm-btn-search:hover {
        background-color: $zeb-color-tertiary;
    }

    body wm-search-book-widget .wm-container wm-matrix-accommodation-view .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item,
    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item,
    body wm-search-book-widget .wm-container wm-stay-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item:hover,
    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.no-availability:hover,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.no-availability:hover
    {
        background-color: rgba($zeb-color-tertiary,0.1);
    }
    body wm-search-book-widget .wm-container wm-matrix-accommodation-view .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.current,
    body wm-search-book-widget .wm-container wm-matrix-accommodation-view .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item:hover,
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .showmatrix,
    body wm-search-book-widget .wm-container .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-name,
    body wm-search-book-widget .wm-container body wm-matrix .wm-matrix-month-posiblities .wm-matrix-period-list button,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-name,
    body wm-matrix .wm-matrix-month-posiblities .wm-matrix-period-list button
    {
        background-color: rgba($zeb-color-primary,1);
        box-shadow: none;
    }
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-name {
        margin-right: 2px!important;
    }
    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability
    {
        background-color: rgba($zeb-color-tertiary,1);
    }

    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability:hover,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability:hover
    {
        background-color: $zeb-color-primary;
    }
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.arrangement :after {
        border-right-color: $zeb-color-primary;
        border-top-color: $zeb-color-primary;
    }

    .wm-container wm-results .wm-results-list .wm-results-item,
    .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content
    {
        box-shadow: none!important;
    }

    .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn-book {
        font-size: 1.25rem;
        font-weight: bold;
        padding-left: 40px;
        padding-right: 40px;
    }
    .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn-book:hover {
        background: $zeb-color-tertiary;
    }

/*  ==========================================================================
    MATRIX
    ========================================================================== */

    body wm-matrix-widget .wm-matrix-month-posiblities .wm-matrix-period-list button,
    body wm-matrix .wm-matrix-month-posiblities .wm-matrix-period-list button
    {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .spinner .double-bounce2 {
        background-color: $zeb-color-tertiary!important;
    }

/*  ==========================================================================
    OTHER CHANGES
    ========================================================================== */

    .wm-container .wm-title,
    .wm-search-book .wm-sb-filter-search .wm-title {
        text-align: left;
        font-size: 15px!important;
        font-weight: bold;
        margin:0;
        color: #3A434B!important;
    }
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button .wm-active-travel-group p,
    body wm-mini-search-book-widget .wm-container wm-date-selection .wm-date-picker-button .wm-selected-date p
    {
        color: $zeb-color-secondary;
    }
    .wm-container wm-travel-group-selection .wm-travel-group-button .wm-active-travel-group p,
    .wm-container wm-date-selection .wm-date-picker-button .wm-selected-date p,
    .wm-container  .wm-selection-row .travel-group-selection .wm-date-picker-button .wm-selected-month,
    .wm-container  .wm-selection-row .wm-date-selection .wm-date-picker-button .wm-selected-month
    {
        font-weight: 600;
        margin: 0;
        font-size: 14px;
    }
    body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-country-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-filters,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-park-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-period-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection {
        background: #fff;
        border: none!important;
        cursor: pointer;
    }
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .travel-group-selection,
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-country-selection,
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-date-selection,
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-filters,
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-park-selection,
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-period-selection,
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-type-selection {
        border: none;
        margin: 0 5px 5px 0;
    }
    body wm-mini-search-book-widget .wm-mini-search-book>div:first-child {
        border-left: .0625rem solid #fff!important
    }
    body wm-mini-search-book-widget .wm-mini-search-book>div:nth-last-child(2) {
        border-right: .0625rem solid #fff!important
    }
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        flex: inherit!important;
        min-width: 150px !important;
        margin: 5px auto;
        height: 40px;
    }
    body.home body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        margin: 20px auto;
    }
    .wm-container .btn.wm-minus,
    .wm-container .btn.wm-plus {
        border: none!important;
        height: 30px;
    }
    body wm-mini-search-book-widget .wm-container .close span {
        color: #fff;
        text-shadow: none;
    }

    .wm-container wm-results .result-text {
        display: none;
    }

    .wm-container .bs-popover-auto[x-placement^=bottom] .arrow::before,
    .wm-container .bs-popover-bottom .arrow::before {
        top: 1px;
        border-bottom-color: #fff;
        filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.2));
    }


