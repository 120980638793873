.container-two-columns {
  .container-holder {
    @include make-row();

    .column.one,
    .column.two {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }
    .column.one {
      @extend .mb-3;
      @extend .mb-md-0;

      @extend .pr-3;
      @extend .pr-md-4;
      @extend .pr-lg-5;
    }
    .column.two {
      @extend .pl-3;
      @extend .pl-md-4;
      @extend .pl-lg-5;
    }
  }
}

.container-three-columns {
	.container-holder {
		@include make-row();

		.column.one,
		.column.two,
		.column.three {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}
		}
		.column.one,
		.column.two {
			@extend .mb-3;
			@extend .mb-md-0;
		}
	}
}

.container-four-columns {
	.container-holder {
		@include make-row();

		.column.one,
		.column.two,
		.column.three,
		.column.four {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(3);
			}
		}
		.column.one,
		.column.two,
		.column.three {
			@extend .mb-3;
			@extend .mb-lg-0;
		}
		.column.three {
			@extend .mb-sm-0;
		}
	}
}

.container-six-columns {
  .container-holder {
    @include make-row();

    .column.one,
    .column.two,
    .column.three,
    .column.four,
    .column.five,
    .column.six {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(2);
      }
    }
    .column.one,
    .column.two,
    .column.three,
    .column.four,
    .column.five {
      @extend .mb-3;
      @extend .mb-xl-0;
    }
    .column.four,
    .column.five {
      @extend .mb-md-0;
    }
    .column.five {
      @extend .mb-sm-0;
    }
  }
}