.default {
  &.assortiment_category_overview {
    .assortiment_category_overview {
      @include make-row();

      .item {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(3);
        }

        @extend .mb-3;
        @extend .mb-sm-4;
      }
    }
  }

  &.assortiment_category_detail,
  &.assortiment_detail {
    .assortiment_detail {
      @include make-row();
      
      .info {
        @include make-col-ready();
        @include make-col(12);
        @extend .mb-4;
        @extend .pr-lg-5;
        
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
      }
      
      .content {
        @include make-col-ready();
        @include make-col(12);
        @extend .d-flex;
        @extend .flex-column;
        @extend .mb-4;
        @extend .pl-lg-5;

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        .btn-back {
          @extend .btn;
          @extend .btn-pink;
          @extend .align-self-end;
          @extend .mb-3;
        }
      }
    }
    .assortiment_overview {
      @include make-row();

      .item {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(3);
        }

        @extend .mb-3;
        @extend .mb-sm-4;
      }
    }
  }
}