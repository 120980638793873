/*  ==========================================================================
    body
    ========================================================================== */
html {
	font-size: inherit;
} //ivm tommy
body {
	font-family: $font-family-primary;
	font-size: $font-size-body-xs;
	color: $color-dark;
	&.sticky {
		.eyecatcher {
			padding-top: 78px;
		}
	}
}

body:not(.home) {
	.main {
		padding: 60px 0;
	}
}

body.accommodation_overview,
body.blog_post_overview,
body.assortiment_overview,
body.service_overview,
body.accommodation_search_book {
	.main {
		background-color: #efefef;
	}
}

/*  ==========================================================================
    Containers
    ========================================================================== */

.container-fluid {
	max-width: 1400px;
}

.header .container-fluid,
.eyecatcher .container-fluid,
.footer-bottom .container-fluid,
.inspiration .container-fluid {
	max-width: 100%;
}

.container-fluid.extra-padding {
	padding-left: 15vw;
	padding-right: 15vw;
	text-align: center;
}

/*  ==========================================================================
    Sections
    ========================================================================== */

.bg-lightblue {
	background-color: $color-lightblue;
	color: #fff;
	h1,
	a {
		color: #fff;
	}
}
.bg-pink {
	background-color: $color-pink;
}
.bg-gray {
	background-color: #efefef;
}

/*  ==========================================================================
    content
    ========================================================================== */
/*  headings
    ========================================================================== */

h1,
h2,
h3,
h4,
h5 {
	font-family: $font-family-secondary;
}
h1 {
	font-size: 2rem;
}
h2,
h3 {
	font-size: 1.294rem;
}

/*  ==========================================================================
    header
    ========================================================================== */
.header {
	transition: all 0.5s ease;
	.header-socket {
		display: none;
	}

	.header-main {
		.navbar {
			padding-left: 0;
			padding-right: 0;

			.navbar-collapse {
				margin-left: auto;
			}
			// language
			.language {
			}
			@media (max-width: 1199px) {
				.navbar-toggler {
					position: absolute;
					top: 22px;
					right: 5px;
				}
				.language {
					position: absolute;
					right: 60px;
					top: 12px;
					z-index: 9999;
					border: none;
					.dropdown-toggle {
						border: none !important;
						text-align: right;
					}
					&:hover,
					.dropdown.show {
						background-color: #33a4b7;

						.dropdown-menu {
							margin: 15px 0 15px;
							.dropdown-item {
								padding: 0.25rem 15px;
							}
						}
					}
				}
			}
		}
	}
	.logo-header {
		transition: all 0.5s ease;
		.logo {
			position: relative;
			width: 58px;
			transition: all 0.5s ease;
			img {
				position: absolute;
				z-index: 10;
				width: 100%;
			}
		}
	}

	&.sticky {
		z-index: 100;
		width: 100%;
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
		.logo-header {
			.logo {
				width: 58px;
			}
		}
	}
}

/*  ==========================================================================
    Logo
    ========================================================================== */

.logo-header {
	.logo-info {
		z-index: 10000;
		height: 60px;
		h1 {
			display: flex;
			flex-direction: column;
			margin: 0;
			line-height: 1.2rem;
		}
		.desc {
			font-family: $font-family-secondary;
			font-size: 1.06rem;
			line-height: 1.2rem;
			color: #000;
			margin-bottom: 5px;
			@media (max-width: 575px) {
				margin-bottom: 0;
				font-size: 0.85rem;
			}
		}
		.name {
			font-family: $font-family-secondary;
			font-size: 1.75rem;
			color: #fff;
			font-weight: 700;
			text-transform: uppercase;
			@media (max-width: 575px) {
				font-size: 1.3rem;
			}
		}
		.location {
			font-family: $font-family-primary;
			font-size: 1.75rem;
			color: #fff;
			font-weight: 300;
			font-size: 1.06rem;
			@media (max-width: 575px) {
				font-size: 0.8rem;
				line-height: 0.8rem;
			}
		}
	}
}

/*  ==========================================================================
    Menu / Navigation
    ========================================================================== */

// overidings
.navbar {
	width: 100%;
	.navbar-collapse {
		flex-grow: inherit;
	}
	.navbar-toggler {
		background: #fff;
	}

	.navbar-nav {
		margin: 15px 0 0;

		.nav-item {
			.nav-link {
				font-family: $font-family-secondary;
				color: #fff;
				font-weight: 700;
				font-size: 1.117rem;
				text-decoration: none;
				padding: 0.5rem 0 !important;
				border-top: 1px solid rgba(255, 255, 255, 0.5);
				&:hover {
					color: $color-darkblue;
				}
				.dropdown-toggle::after {
					vertical-align: 0.1em;
				}
			}
			&.active {
				.nav-link {
					color: $color-darkblue;
				}
			}
		}
	}

	.dropdown-menu {
		margin: 0 0 15px;
		border-radius: 0;
		border: none;
		background: none;

		.dropdown-item {
			display: flex;
			justify-content: center;
			color: #fff;
			text-decoration: none;
			padding: 0.25rem 0;
		}
		a:hover {
			color: #000;
		}
		a:active {
			background: $color-lightblue;
		}
		@include media-breakpoint-up(md) {
			.nav-link {
				color: #444 !important;
				font-weight: 400 !important;
			}
		}
	}

	.site_switcher {
		display: block;
	}

	.navbar-toggler {
		background: none;
		padding: 0;
		.navbar-toggler-icon {
			color: #fff;
		}
		&:focus {
			outline: none;
		}
	}
	.language {
		border-left: 1px solid #fff;
		margin: 0 0 0 10px;
		padding-left: 10px;

		width: auto;

		.nav-link {
			border: none;
		}
	}
}
.dropdown-toggle::after {
	vertical-align: 0.1em;
}

/*  ==========================================================================
    ZEB mini
    ========================================================================== */

.zeb-mini {
	position: relative;

	.mini_search_book {
		right: 0;
		h1 {
			display: none;
			font-size: 1.75rem;
			text-transform: uppercase;
		}
		// hide widget responsive
		wm-mini-search-book-widget {
			display: none;
			.wm-btn-search {
				border-radius: 3rem !important;
				padding: 0 !important;
			}
		}
		// show different button responsive
		.btn-book {
			position: absolute;
			left: 50%;
			z-index: 99;

			display: block;
			background: $color-pink !important;
			border-color: $color-pink !important;

			width: 200px;
			margin: -18px 0 0 -100px;

			&:hover {
				background: $color-darkblue !important;
				border-color: $color-darkblue !important;
			}
		}
		.sticky.btn-book {
			position: fixed;
			display: block;
			top: 93px;
			border-radius: 0.2rem;
			left: 250px;
			z-index: 999;
			box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
		}
	}
}
.home {
	.zeb-mini {
		.mini_search_book {
			left: 0;
		}
	}
}

/*  ==========================================================================
    ZEB overzicht
    ========================================================================== */

// price
.module-overview
	.wm-container
	wm-results
	.wm-results-list
	.wm-results-item
	.wm-card-content
	.wm-full-price
	> p {
	text-align: right;
	color: $color-darkblue;
}
.module-overview
	.wm-container
	wm-results
	.wm-results-list
	.wm-results-item
	.wm-card-content
	.wm-full-price
	p
	.total.deposit {
	font-size: 12px;
	font-weight: 400;
	display: block;
}
.module-overview
	.wm-container
	wm-results
	.wm-results-list
	.wm-results-item
	.wm-card-content
	.wm-full-price
	p
	.total,
.module-overview
	.wm-container
	wm-results
	.wm-results-list
	.wm-results-item
	.wm-card-content
	.wm-full-price
	p
	.price-text {
	display: inline;
}
.module-overview
	.wm-container
	wm-results
	.wm-results-list
	.wm-results-item
	.wm-card-content
	.wm-btn-book {
	min-width: 150px;
}

/*  ==========================================================================
    USPs
    ========================================================================== */

.usps {
	background: #fff;
	padding: 30px 50px;
	box-shadow: 0 0 15px hsla(0, 0%, 0%, 0.34);
	.card {
		border: none;
	}
	h2,
	h3,
	h4 {
		color: $color-dark;
		font-size: 1.765rem;
		font-weight: 600;
		margin: 0;
	}
	p {
		color: $color-dark;
		font-size: 0.88rem;
		font-weight: 600;
	}
	.card {
		margin: 0;
		flex-direction: row;
		.card-image {
			padding: 0 15px;
			background: none;
			border: none;
			text-align: right;
			img {
				width: 60px;
				border-radius: 100%;
			}
			i {
				color: #444;
				font-size: 50px;
			}
		}
		.card-body {
			position: relative;
			padding: 0 15px;
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 5px;
				height: 50px;
				width: 2px;
				background: $color-pink;
			}
		}
	}
}

/*  ==========================================================================
    Who We Are
    ========================================================================== */

.who-we-are {
	h1,
	h2,
	h3,
	h4 {
		color: $color-dark;
	}
	p {
		font-size: 1.176rem;
	}
	.teamphoto {
		img,
		.logo {
			max-width: 100%;
			margin-top: auto;
			height: auto;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.btn,
	.btn:not([href]):not([tabindex]) {
		background-color: $color-pink;
		border-color: $color-pink;
		align-self: center;
	}
}

/*  ==========================================================================
    Reviews
    ========================================================================== */

.reviews {
	.card,
	.owl-nav {
		width: 80%;
		margin: 0 auto;
		text-align: center;
		background: none;
		.btn,
		.btn:not([href]):not([tabindex]) {
			background-color: $color-pink;
			border-color: $color-pink;
		}
		.card-caption {
			display: flex;
			flex-direction: column;
			-ms-flex-direction: column;
			.card-title {
				order: 1;
			}
			.card-subtitle {
				order: 3;
				font-family: $font-family-primary;
				font-size: 1rem;
				color: #3a434b;
				font-style: italic;
				font-weight: 400;
			}
			.card-text {
				order: 2;
			}
			.btn {
				order: 4;
				align-self: center;
				margin-top: 15px;
			}
		}
	}
	.owl-prev,
	.owl-next {
		position: absolute;
		top: 40%;
	}
	.owl-prev {
		left: 0;
	}
	.owl-next {
		right: 0;
	}
}

/*  ==========================================================================
    Photoalbum
    ========================================================================== */

.photoalbum {
	.image {
		img {
			width: 100%;
		}
	}
}

/*  ==========================================================================
    Highlights
    ========================================================================== */
.highlights {
	padding: 50px 0;
	text-align: center;

	.container-holder {
		justify-content: center;

		& > div {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

/*  ==========================================================================
    Images
    ========================================================================== */

.image {
	transition: all 0.5s ease;
	&:hover {
		opacity: 0.8;
	}
}

/*  ==========================================================================
    Bundle
    ========================================================================== */

.back-to-overview {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	a {
		@extend .btn;
		@extend .btn-pink;
	}
}

/*  ==========================================================================
    Footer
    ========================================================================== */

footer {
	.footer-top {
		color: #fff;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $color-dark;
		}
		a {
			font-weight: 400;
			text-decoration: none;
		}
		ul {
			padding-left: 0;
			list-style: none;
		}
	}

	.footer-bottom {
		background: #fff;
		padding: 3rem 0;
		text-align: center;
		a {
			color: $color-pink;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: 1.765rem;
			color: $color-pink;
			margin: 0;
		}
		ul {
			justify-content: center;
			padding-left: 0;
			list-style: none;
		}
	}
}

/*  ==========================================================================
    Links + buttons
    ========================================================================== */

a,
a:not([href]):not([tabindex]) {
	cursor: pointer;
	color: $color-darkblue;
	transition: all 0.5s ease;
	font-weight: bold;
}

a:hover,
a:focus,
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
	outline: none;
	text-decoration: none;
	color: $color-pink;
}

a[href^="tel:"] {
	pointer-events: auto;
}

.btn,
.btn:not([href]):not([tabindex]) {
	border: 1px solid $color-darkblue;
	background-color: $color-darkblue;
	border-radius: 3rem;
	color: #fff;
	font-weight: 700;
	padding-left: 35px;
	padding-right: 35px;
	text-decoration: none;
	white-space: unset;
	&.btn-pink {
		background-color: $color-pink;
		border-color: $color-pink;
	}
}
.btn:hover,
.btn:not([href]):not([tabindex]):focus,
.btn:not([href]):not([tabindex]):hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	border: 1px solid $color-pink;
	background-color: $color-pink;

	color: #ffffff;
}
.btn-pink:hover,
.btn-pink:not([href]):not([tabindex]):focus,
.btn-pink:not([href]):not([tabindex]):hover {
	border: 1px solid $color-darkblue;
	background-color: $color-darkblue;

	color: #ffffff;
}

.back-to-overview > a {
	@extend .btn;

	margin-bottom: 25px;
}

/*  ==========================================================================
    Other helper overridings
    ========================================================================== */

.border-left,
.border-right {
	border-color: $color-pink !important;
	border-width: 0 !important;
}
