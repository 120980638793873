/*  ==========================================================================
    extra small devices (portrait phones, less than 576px)
    no media query since this is the default in Bootstrap
    ========================================================================== */

    $font-size-body-md: 15px;
    $font-size-body-lg: 16px;
    $font-size-body-xl: 17px;

/*  ==========================================================================
    small devices (landscape phones, 576px and up)
    ========================================================================== */

@media (min-width: 576px) {

  .eyecatcher {
    &.bg-carousel {
      .carousel {
        .carousel-inner {
          .carousel-item {
            .carousel-holder {
              .container {
                display: flex;
                justify-content: flex-end;
              }

              .carousel-caption {
                max-width: 750px;
                padding-top: 150px;
                padding-bottom: 140px;
                text-align: right;

                .carousel-title {
                  font-size: 3.4375rem; /* 55px */
                  font-weight: lighter;
                }
              }
            }
          }
        }
      }

      &.large {
        min-height: 75vh;

        .carousel {
          .carousel-inner {
            .carousel-item {
              min-height: 75vh;

              .carousel-holder {
                min-height: 75vh;
              }
            }
          }
        }
      }
    }
  }

  .inspiration {
    .card {
      .card-img-overlay {
        padding: 25px 35px;

        .card-caption {
          .card-title {
            font-size: 1.875rem; /* 30px */
            font-weight: lighter;
          }
        }
      }
    }
  }

}

/*  ==========================================================================
    medium devices (tablets, 768px and up)
    ========================================================================== */

@media (min-width: 768px) {

    /* base
    ========================================================================== */
    body { font-size: $font-size-body-md; }

    body:not(.home) {
        .main, main { padding: 120px 0 60px; }
    }

    header .header-main .navbar { padding-left: inherit; padding-right: inherit; }


    a[href^="tel:"] {  pointer-events: none;  }

    h1      {   font-size: 2.353rem; }
    h2, h3  {   font-size: 1.412rem; }

    li.dropdown:hover > .dropdown-menu {
        display: block;
    }

    .who-we-are {
        .btn, .btn:not([href]):not([tabindex]) {
            align-self: flex-start;
        }
    }

    .zeb-mini {

        .mini_search_book {
            position: absolute;
            bottom: -65px;
            width: 245px;
            z-index: 10;
            max-height: 550px;
            min-height: 265px;
            background: #83c789;
            color: #fff;
            padding: 20px;
            font-size: 1rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

            h1 {
                display: block;
            }

            wm-mini-search-book-widget {
                display: block;
            }
            .btn-book {
                display: none;
            }

        }
    }


    .highlights {
      text-align: left;

      .container-holder {
        & > div {
          padding-top: 5px;
          padding-bottom: 45px;

          &:first-child {
            border-right: 2px solid $color-pink;
          }
          &:last-child {
            border-left: 2px solid $color-pink;
          }
        }
      }
    }

    .footer {
      .footer-top {
        .col-md {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }

}

/*  ==========================================================================
    large devices (desktops, 992px and up)
    ========================================================================== */

@media (min-width: 992px) {

    body { font-size: $font-size-body-lg; }
    h1      {   font-size: 3rem; }
    h2, h3  {   font-size: 1.647rem; }

    .border-left, .border-right {
        border-width: 1px!important;
    }
    .reviews {

        .card {
            width: 60%;
            margin: 0 auto;
            text-align: center;
            background: none;
        }

    }

    .footer {
      .footer-top {
        .col-md {
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }
      }
    }

}


/*  ==========================================================================
    extra large devices (large desktops, 1200px and up)
    ========================================================================== */

@media (min-width: 1200px) {

    body    {   font-size: $font-size-body-xl;  }
    h1      {   font-size: 3.235rem; }
    h2, h3  {   font-size: 1.875rem; }
    .container {  padding: 0; }
    .eyecatcher .carousel-caption .caption-content .caption-text {  font-size: 3.235rem;   }
    .header .logo-header .logo {  width: 128px; }
    .navbar .navbar-collapse .navbar-nav {
        margin: inherit;
    }
    .navbar .navbar-nav .nav-item .nav-link {
        padding: .5rem 1rem!important; border: 0;
    }
   .navbar .navbar-nav .nav-item .dropdown-menu {
        margin: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        background: #fff;
        .dropdown-item {
            color: #444;
            font-weight: 400;
            text-decoration: none;
            padding: .25rem 1.5rem;
        }
    }

    .reviews {
        .card {
            width: 40%;
        }
        .owl-prev { left: 20%; }
        .owl-next { right: 20%; }

    }



}
