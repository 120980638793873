/*  ==========================================================================
    Configure bootstrap
    ========================================================================== */
    $theme-colors: (
      "primary": #32c060,
      "danger": #ff4136
    );


/*  ==========================================================================
    Import
    ========================================================================== */
/*  Webfont
    ========================================================================== */
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Sunflower:300,500,700');


/*  ==========================================================================
    Variabelen
    ========================================================================== */
/*  Fonts
    ========================================================================== */
    $font-family-primary: 'Open Sans', serif;
    $font-family-secondary: 'Sunflower', sans-serif;
    $font-size-body-xs: 15px;

/*  Colors
    ========================================================================== */
    $color-lightblue: #33a4b7;
    $color-darkblue: #0476A6;
    $color-pink: #8c495d;
    $color-dark: #3A434B;
