.card {
    .card-img-overlay {
        .card-caption {
            .card-text {
                max-height: 70px;
                overflow: hidden;
                margin-bottom: 15px;
            }
        }
    }
}

/*  ==========================================================================
    base
    ========================================================================== */
    .card {
      border: none;
      border-radius: 0;

      .card-image {
        .card-img-top {
            border-radius: 0;
        }
      }

      .card-img-overlay {
          transition: all 0.5s ease;

          .card-title {
              font-family: $font-family-secondary;
              color: #FFFFFF;
          }

          .card-text {
              font-size: 0.875rem; /* 14px */
              color: #FFFFFF;
          }

          &:hover {
              background: rgba($color-dark, 0.2);
          }
      }
  }


/*  ==========================================================================
    inspiration
    ========================================================================== */
    .inspiration {
      .container-fluid { padding: 0 5px; }

      .row {
        margin: -2.5px;

        & > div {
          margin-bottom: 0 !important;
          padding: 2.5px;
        }
      }

      .card {
        margin-bottom: 0;

        .card-img-overlay {
          display: flex;
          align-items: flex-end;

          padding: 35px 25px;

          .card-caption {
            .card-title {
              font-size: 1.5625rem; /* 25px */
              font-weight: bold;
            }

            .card-text {
              display: none;
            }

            .btn-link {
              text-decoration: none;
              color: #FFFFFF;

              &::after {
                margin-left: 10px;

                font-family: 'Font Awesome 5 Pro';
                content: '\f061';
              }
            }
          }
        }
      }
    }
