.hamburger {
    cursor: pointer;
    transition-property: opacity,filter;
    transition-duration: .15s;
    transition-timing-function: linear;
    text-transform: none;
}
.hamburger:hover {
    opacity: 0.7;
}
.hamburger-box {
    position: relative;
    padding: 10px 20px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
}
.hamburger-inner::before {
    top: -8px;
}
.hamburger-inner::after {
    bottom: -8px;
}
/* Spin */
.hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}