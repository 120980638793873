.card {
  .card-body {
    .card-caption {
      a {
        .card-title {
          color: #3a434b;

          &:hover {
            color: #8c495d;
          }
        }
      }
      
      .card-btn {
        @extend .btn;
        @extend .btn-primary;
      }
    }
  }
}